import React, { useState } from "react"
import { HeaderSectionQuery, HeaderSectionTopQuery } from "../../prismic/staticQueries"

import { connect, useSelector } from "react-redux"
import TopBar from "./top-bar"
import LanguagesDropdown from "./languages-dropdown"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import { Link } from "gatsby"
import SEO from './../seo';

const HeaderSection = () => {
  const language = useSelector(state => state.language)
  const {
    logo,
    body,
  } = HeaderSectionQuery(language);

  const {
    portal_icon,
    portal_label,
    portal_link,
  } = HeaderSectionTopQuery(language)

  // console.log('ggg ',portal_icon,portal_label,portal_link)

  const isRtl = language === "ar-ae" 

  const [activeItem, setActiveItem] = useState(null)

  const toggleMenu = index => {
    setActiveItem(activeItem === index ? null : index)
  }

  return (
    <>
            {/* <SEO 
              title="AccuIndex - Trade Forex, Shares, Commodities and CFDs | AccuIndex" 
              description="Accuindex provides over 200 financial instruments: currency, commodity, share CFDs  and more. Learn more about the benefits and sign up for an account today. Transparent Fee Structure."
            /> */}

      <section
        className="header header--2"
        style={{ direction: isRtl ? "rtl" : "ltr" }}
      >
        <TopBar />

        <div className="menu_area menu1">
          <div className="container">
            <Navbar collapseOnSelect expand="lg">
              <Navbar.Brand href="/" className="pr-4">
                <img src={logo.url} alt="" />
              </Navbar.Brand>
              
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="">
                
                  <div className="nav-item dropdown">
                  <ul className="mobile-only">
                          <li>
                            <a
                              className="smallIcon"
                              href={portal_link[0].text}
                              target="_blank" rel="noreferrer"
                            >
                              <img alt="ddd" src={portal_icon.url} /> {portal_label[0].text}
                            </a>
                          </li>
                        </ul>

                    <button
                      className="navbar-toggler"
                      type="button"
                      data-
                      toggle="collapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                    
                      <div className="top_bar--lang align-self-center order-2 abc">
                       
                      {/* <div className="top_bar_social">
                        <ul>
                          <li>
                            <a
                              className="smallIcon"
                              href={portal_link[0].text}
                              target="_blank" rel="noreferrer"
                            >
                              <img alt="ddd" src={portal_icon.url} /> {portal_label[0].text}
                            </a>
                          </li>
                        </ul>
                      </div> */}
                       
                        <LanguagesDropdown />
                      </div>
                    </button>
                  </div>

                  {body.map((menue, index) => (
                    <div key={'keys-'+index}>
                      {menue.items.length === 0 && (
                        <div className="nav-item dropdown" key={'upper-key-'+index}>
                          <Link key={'upper-key-ddf-'+index}
                            className="nav-link homeMenue"
                            to={menue.primary.menu_link[0].text}
                          >
                            {" "}
                            {menue.primary.menu_name[0].text}
                          </Link>
                        </div>
                      )}

                      {menue.items.length > 0 && (
                        <NavDropdown
                          key={'dropdown-key-'+index}
                          title={menue.primary.menu_name[0].text}
                          id="collasible-nav-dropdown"
                          onToggle={() => {
                            toggleMenu(index)
                          }}
                          // onMouseEnter={() => setActiveItem(index)}
                          // onMouseLeave={() => setActiveItem(index)}
                          show={activeItem === index}
                          className={`${
                            isRtl
                              ? "mainMenue menu-arrow-ar"
                              : "mainMenue menu-arrow "
                          }`}
                        >
                          <ul className="navbar-nav">
                            {menue.items.map((sub_menue, subMenueIndex) => (
                              <div key={'sdfsdfs-'+subMenueIndex}>
                                {
                                  (sub_menue.open_external === true && (
                                    <a
                                    key={'inner-link-key-'+subMenueIndex}
                                    className="dropdown-item subMenue"
                                      target="_blank"
                                      rel="noreferrer"
                                      href={sub_menue.sub_menu_link[0].text}
                                    >
                                      {" "}
                                      {sub_menue.sub_menu_name[0].text}{" "}
                                    </a>
                                  )) ||
                                    (sub_menue.open_external === false && (
                                      <Link
                                      key={'inner-link-key-'+subMenueIndex}

                                        className="dropdown-item subMenue"
                                        to={sub_menue.sub_menu_link[0].text}
                                      >
                                        {" "}
                                        {sub_menue.sub_menu_name[0].text}{" "}
                                      </Link>
                                    ))

                                  // <NavDropdown.Item className="">
                                  // </NavDropdown.Item>
                                }
                              </div>
                            ))}
                          </ul>
                        </NavDropdown>
                      )}
                    </div>
                  ))}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(HeaderSection)
